import React from 'react';

const AboutComponent = () => {
  return (
    <div style={{marginTop:"3rem"}}>
      <div className="elementor-icon-box-content">
        <h3 className="elementor-icon-box-title">
          <span>Megbízható vállalkozás</span>
        </h3>
        <p className="elementor-icon-box-description">
          vagyunk, mely immár több mint 10 éve van jelen a piacon és hisz abban, hogy a minőségi kávézás élménye nem helyhez kötött.
        </p>
      </div>

      <div className="elementor-icon-box-content">
        <h3 className="elementor-icon-box-title">
          <span>Arra törekszünk</span>
        </h3>
        <p className="elementor-icon-box-description">
          hogy professzionális kávégépeinkkel, a gondosan válogatott alapanyagokkal és felkészült baristáinkkal tökéletes kávéélményt nyújtsunk vendégeinknek.
        </p>
      </div>

      <div className="elementor-icon-box-content">
        <h3 className="elementor-icon-box-title">
          <span>Lelkes baristáink</span>
        </h3>
        <p className="elementor-icon-box-description">
          igény szerint előadásokat, workshopokat is tartanak, beavatva a vendégeket a kávék izgalmas világába, megismertetve őket az alapvető fortélyokkal.
        </p>
      </div>

      <div className="elementor-icon-box-content">
        <h3 className="elementor-icon-box-title">
          <span>Mert több előnnyel is jár</span>
        </h3>
        <p className="elementor-icon-box-description">
          Magas létszám is gyorsan kiszolgálható anélkül, hogy mindez nagy területet venne igénybe.
          <br />
          Érkezéstől számított <strong>30 percen belül</strong> már gőzölöghet is az illatos, friss kávé a poharakban.
          <br />
          Egyszerűen és problémamentesen <strong>mozgatható elemekkel</strong> dolgozunk.
          <br />
          A kávék pedig <strong>felejthetetlenül finomak</strong>!
        </p>
      </div>
    </div>
  );
};

export default AboutComponent;
