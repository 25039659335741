import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ProjectCards from "./ProjectCards.js";
// import kep from "./";
function Services() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Amiket <strong className="purple">nyújtunk </strong>
        </h1>
        <p style={{ color: "white" }}>
          Az alábbi szolgáltatások...
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          
        </Row>
      </Container>
      <Container style={{display:"flex",flexDirection:"row",flexWrap:"wrap",padding:"4rem"}}>
        <ProjectCards title="Ez itt egy szöveg" isBlog="true" description="finom kávék egy gyors, természettudatos autóból"/>
        <ProjectCards title="Ez itt egy szöveg" isBlog="true" description="finom kávék egy gyors, természettudatos autóból"/>
        <ProjectCards title="Ez itt egy szöveg" isBlog="true" description="finom kávék egy gyors, természettudatos autóból"/>
        <ProjectCards title="Ez itt egy szöveg" isBlog="true" description="finom kávék egy gyors, természettudatos autóból"/>
        <ProjectCards title="Ez itt egy szöveg" isBlog="true" description="finom kávék egy gyors, természettudatos autóból"/>
        <ProjectCards title="Ez itt egy szöveg" isBlog="true" description="finom kávék egy gyors, természettudatos autóból"/>
        <ProjectCards title="Ez itt egy szöveg" isBlog="true" description="finom kávék egy gyors, természettudatos autóból"/>
        <ProjectCards title="Ez itt egy szöveg" isBlog="true" description="finom kávék egy gyors, természettudatos autóból"/>
      </Container>
    </Container>
  );
}

export default Services;
