import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import Form from 'react-bootstrap/Form';
import {
  AiFillInstagram,AiFillFacebook,AiOutlinePhone,AiOutlineMail
} from "react-icons/ai";

function Contact() {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform form validation
    if (formData.name && formData.email) {
      // Form data is valid, proceed with form submission
      console.log('Form submitted:', formData);
      // You can reset the form state if needed
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    } else {
      // Display an error or validation message
      console.log('Név és Email mezők kitöltése kötelező!');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target; 
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };


  return (
    <div className="form-page">
    <div className="left-form" style={{ color: 'white' }}>
      <p style={{ fontSize:'xx-large' ,marginTop:'5rem'}}>Kapcsolat</p>
      <p style={{ fontSize:'medium' ,marginTop:'5rem'}}>Farkas Csaba</p>
      <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
        <AiOutlinePhone/>
        <p style={{marginTop:'1rem'}}>+36-20-549-5594</p>
      </div>
      <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
        <AiOutlineMail/>
        <p style={{marginTop:'1rem'}}>info@vespressocoffee.hu</p>
      </div>
      <div>
        <p>Kövess minket!</p>
        <a
          href="https://www.instagram.com/vespresso.coffee/"
          target="_blank"
          rel="noreferrer"
          className="icon-colour home-social-icons"
          >
          <AiFillInstagram />
        </a>

        <a
          href="https://www.facebook.com/VespressoCoffee"
          target="_blank"
          rel="noreferrer"
          className="icon-colour home-social-icons"
          >
          <AiFillFacebook />
        </a>
      </div>
    </div>

    <div className="form-container">
    <Form className="form-section" style={{ color: 'white' }} onSubmit={handleSubmit}>
      <Form.Group controlId="formTitle">
        <Form.Label className="form-title-1">Kérd egyedi ajánlatunkat!</Form.Label>
        <Form.Label className="form-title-2">Vagy írj nekünk, ha bármilyen kérdésed van.</Form.Label>
      </Form.Group>
      <div className="my-5"></div>
      <Form.Group controlId="formName">
        <Form.Label>Név (*)</Form.Label>
        <Form.Control
          type="text"
          name="name"
          placeholder=""
          value={formData.name}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <div className="my-4"></div>
      <Form.Group controlId="formEmail">
        <Form.Label>Email (*)</Form.Label>
        <Form.Control
          type="email"
          name="email"
          placeholder=""
          value={formData.email}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <div className="my-4"></div>
      <Form.Group controlId="formSubject">
        <Form.Label>Tárgy</Form.Label>
        <Form.Control
          type="text"
          name="subject"
          placeholder=""
          value={formData.subject}
          onChange={handleChange}
        />
      </Form.Group>

      <div className="my-4"></div>
      <Form.Group controlId="formMessage">
        <Form.Label>Üzenet</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="message"
          placeholder=""
          value={formData.message}
          onChange={handleChange}
        />
      </Form.Group>

      <div className="my-5"></div>
      <Button variant="danger" type="submit" className="custom-submit-button">
        Küldés
      </Button>
      <Particle />
    </Form>
    </div>

    </div>
  );
}

export default Contact;
